import './App.css';
import Routes from './Routes';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store, persistor } from './store/store';
import AuthProvider from './AuthProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { Suspense, useCallback, useEffect } from 'react';
import { Helmet } from "react-helmet";

const structuredData = { "@context": "https://schema.org", "@type": "Games", "name": "What is My IP Address? | Free IP Lookup & IP Settings Info - MyIPInfo.io", "url": "https://myipinfo.io/", "logo": "https://myipinfo.io/logo.png", "description": "Easily find your IP address and understand its settings with MyIPInfo.io . Our simple and fast IP lookup tool gives you insights into your IP, location, and internet address details—helping you stay informed and secure online." };

function App() {
  /** START: Cookiebot - YD-34 -  GDPR Popup for all pages*/
  // useEffect(() => {
  //   let script, script1;

  //   setTimeout(() => {
  //     // Cookiebot Script
  //     script = document.createElement('script');
  //     script.src = 'https://consent.cookiebot.com/uc.js';
  //     script.id = 'Cookiebot';
  //     script.dataset.cbid = 'd3f9d706-fc2b-4b89-88eb-c97b354963bc';
  //     script.dataset.blockingmode = 'auto';
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     document.head.appendChild(script);
  //   }, 10000);

  //   return () => {
  //     document.head.removeChild(script);
  //     document.head.removeChild(script1);
  //   };
  // }, []);
  /** END: Cookiebot - YD-34 -  GDPR Popup for all pages*/

  // Load Google Tag Manager Script
  // useEffect(() => {
  //   (function (w, d, s, l, i) {
  //     w[l] = w[l] || [];
  //     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l !== 'dataLayer' ? '&l=' + l : '';
  //     j.defer = true;
  //     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, 'script', 'dataLayer', 'GTM-KFL8FCPS'); //GTM-PLNKXTW
  // }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY === 0) {
      let bodyEl = document.getElementsByTagName('body')[0];
      if (document.querySelector(`[data-anchor-status="displayed"]`)) {
        if (bodyEl.style?.paddingBottom === '0px') {
          bodyEl.style.padding = '92px 0px 0px;';
          bodyEl.style.paddingTop = '92px';
        }
      }
      if (document.querySelector(`[data-anchor-status="dismissed"]`)) {
        if (bodyEl.style?.paddingBottom === '0px') {
          bodyEl.style.padding = '30px 0px 0px;';
          bodyEl.style.paddingTop = '30px';
        }
      }
    }
  }, []);

  const handleClick = useCallback((e) => {
    if (
      e.target?.className === 'grippy-host' &&
      e.target?.parentElement?.dataset?.anchorStatus
    ) {
      let bodyEl = document.getElementsByTagName('body')[0];
      if (e.target?.parentElement?.dataset?.anchorStatus === 'dismissed') {
        if (bodyEl.style?.paddingBottom === '0px') {
          bodyEl.style.padding = '92px 0px 0px;';
          bodyEl.style.paddingTop = '92px';
        }
      }
      if (e.target?.parentElement?.dataset?.anchorStatus === 'displayed') {
        if (bodyEl.style?.paddingBottom === '0px') {
          bodyEl.style.padding = '30px 0px 0px;';
          bodyEl.style.paddingTop = '30px';
        }
      }
    }
  }, []);

  useEffect(() => {
    /*START: To remove extra space on scrollup when ADs shown */
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleClick);
    /*END: To remove extra space on scrollup when ADs shown */

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick, handleScroll]);

  return (
    <Suspense fallback={<></>}>
      <Helmet> 
        <meta
          name="description"
          content="Find your IP address and its settings with MyIPInfo.io . Fast IP lookup tool provides insights into your IP, location, and internet details for online security."
        />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <Router>
              <Routes />
            </Router>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
