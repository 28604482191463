import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center h-[80vh]'>
      <h1 className='font-extrabold text-9xl mb-2'>404</h1>
      <h2 className='font-medium text-xl text-gray-400 mb-4'>Page Not Found</h2>
      <Link to={"/"} className="bg-[#02B1BD] px-4 py-3 uppercase font-semibold rounded-2xl text-white flex flex-row justify-center gap-3 items-center">Back to Home page</Link>
    </div>
  )
}

export default NotFound;