import React, { Fragment, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/Home/Home";
import Redirect from "./components/Redirect";

const Footer = React.lazy(() => import("./components/Footer/Footer"));

// Lazy Loaded Routes
const UserAgent = React.lazy(() => import("./views/UserAgent/UserAgent"));
const IpLookUp = React.lazy(() => import("./views/IpLookUp/IpLookUp"));
const SpeedTest = React.lazy(() => import("./views/SpeedTest/SpeedTest"));
const TermsAndCondition = React.lazy(() => import("./views/TermsAndCondition"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const JsonToCSV = React.lazy(() => import("./views/JsonToCSV/JsonToCSV"));
const JsonCSVOrganizer = React.lazy(() => import("./views/JsonCSVOrganizer/JsonCSVOrganizer"));
const URLWhiteLister = React.lazy(() => import("./views/URLWhiteLister/URLWhiteLister"));

// Lazy Load Functions
const ScrollToTop = React.lazy(() => import("./ScrollToTop"));

const Routes = () => {
  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <div className="pt-[58px] lg:pt-0">
          <Suspense fallback={<div className="min-h-screen">Loading Routes...</div>}>
            <Switch>
              <Route exact path="/redirect" component={Redirect} />
              <Route exact path="/speed-test" component={SpeedTest} />
              <Route exact path="/ip-lookup" component={IpLookUp} />
              <Route exact path="/user-agent" component={UserAgent} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndCondition}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/json-export" component={JsonToCSV} />
              <Route exact path="/json-csv-organizer" component={JsonCSVOrganizer} />
              <Route exact path="/url-whitelister" component={URLWhiteLister} />
              <Route exact path="/" component={Home} />
            </Switch>
          </Suspense>
        </div>
      </Fragment>
      <Footer />
    </Router>
  );
};

export default Routes;
