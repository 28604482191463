import {
    GET_USER_AGENT_LOOKUP_REQUEST,
    GET_USER_AGENT_LOOKUP_SUCCESS,
    GET_USER_AGENT_LOOKUP_FAIL,
    GET_SYSTEM_INFO_REQUEST,
    GET_SYSTEM_INFO_SUCCESS,
    GET_SYSTEM_INFO_FAILED,
    GET_EXTERNAL_LOGGER_REQUEST,
    GET_EXTERNAL_LOGGER_SUCCESS,
    GET_EXTERNAL_LOGGER_FAILED
} from '../types';

const initialState = {
    lookupAgentLoader: false,
    lookupAgentData: {},
    lookupAgentError: {},
    readUserAgentSystemInfo:'',
    systemInfoLoader:false,
    externalloggerLoader:false,
    loggerData:[] //External Logger 
}

const userAgentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_AGENT_LOOKUP_REQUEST:
            return { ...state, lookupAgentLoader: true }

        case GET_USER_AGENT_LOOKUP_SUCCESS:
            return { ...state, lookupAgentLoader: false, lookupAgentData: action.payload }

        case GET_USER_AGENT_LOOKUP_FAIL:
            return { ...state, ipLookupLoader: false, lookupAgentError: { Error: 'Unknown Error Occured!' } }

        case GET_SYSTEM_INFO_REQUEST:
            return { ...state, systemInfoLoader: true }

        case GET_SYSTEM_INFO_SUCCESS:
            return { ...state, systemInfoLoader:false,readUserAgentSystemInfo:action.payload }

        case GET_SYSTEM_INFO_FAILED:
                return { ...state, systemInfoLoader:false,readUserAgentSystemInfo:action.payload }

        case GET_EXTERNAL_LOGGER_REQUEST:
            return { ...state, externalloggerLoader:true }
        case GET_EXTERNAL_LOGGER_SUCCESS:
            return { ...state, externalloggerLoader:false, loggerData:action.payload }
        case GET_EXTERNAL_LOGGER_FAILED:
            return { ...state, externalloggerLoader:false, loggerData:[] }
        
        default:
            return state;
    }
}
export default userAgentReducer;