//import axios from '../axiosConfig'
//import { BASE_URL } from "../config";
import {
    GET_USER_FOUND,
    LOGOUT_SUCCESS
} from "../types";
 
export const LoginAction = (data) => (dispatch) => {
    return dispatch({ type: GET_USER_FOUND, payload: data })
}

export const LogoutAction = () => (dispatch)=>{
    return dispatch({ type: LOGOUT_SUCCESS })
    // axios.get(`${BASE_URL}logout-event`)
    //     .then((response) => { return dispatch({ type: LOGOUT_SUCCESS }) })
    //     .catch((error) => { return dispatch({ type: LOGOUT_SUCCESS }) })
}