import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import allReducers from "../reducers/rootReducer";
import {createLogger} from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import Config from "Config"

// const updatedApplyMiddleware = Config.NODE_ENV == "development" ? applyMiddleware(thunk, promise, createLogger()) : applyMiddleware(thunk, promise)
const updatedApplyMiddleware =  applyMiddleware(thunk, promise, createLogger()) 


const rootConfig = {
    key: "root",
    storage,
    whitelist: ["authReducer"],
};
  
const persistedReducer = persistReducer(rootConfig, allReducers);

const store = createStore(
    persistedReducer,
    updatedApplyMiddleware
);


let persistor = persistStore(store);

export { store, persistor };