import React from "react";
import useResponsive from "../../hooks/useResponsive";

const AdPlug = ({ desktop, mobile }) => {
	const { isMobile } = useResponsive();

	return <div className="max-w-5xl mx-auto flex justify-center">{isMobile ? mobile : desktop}</div>;
};

export default AdPlug;
