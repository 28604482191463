import { useState, useEffect } from 'react';

const useResponsive = () => {
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    // Define breakpoints similar to CSS media queries
    const mobileQuery = window.matchMedia('(max-width: 767px)');
    const tabletQuery = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');
    const desktopQuery = window.matchMedia('(min-width: 1025px)');

    // Handler to update the state based on the current screen size
    const updateScreenSize = () => {
      setScreenSize({
        isMobile: mobileQuery.matches,
        isTablet: tabletQuery.matches,
        isDesktop: desktopQuery.matches,
      });
    };

    // Initial check to set the screen size based on the current window size
    updateScreenSize();

    // Add event listeners to update the state when the screen size changes
    mobileQuery.addListener(updateScreenSize);
    tabletQuery.addListener(updateScreenSize);
    desktopQuery.addListener(updateScreenSize);

    // Cleanup event listeners on component unmount
    return () => {
      mobileQuery.removeListener(updateScreenSize);
      tabletQuery.removeListener(updateScreenSize);
      desktopQuery.removeListener(updateScreenSize);
    };
  }, []);

  return screenSize;
};

export default useResponsive;
