import { useEffect, useRef, useState } from "react";

const AdSlot = ({ adUnitId, adSlotId, size }) => {
    const adRef = useRef(null);
    const [showAd, setShowAd] = useState(false);
    const [hasRequested, setHasRequested] = useState(false);
    const timeoutRef = useRef(null);
    const [adSize, setAdSize] = useState([0, 0]);

    useEffect(() => {
        if (!window.pbjs) {
            console.error("❌ Prebid.js not loaded!");
            return;
        }

        function waitForCMP() {
            if (hasRequested) return; // Prevent duplicate requests
            if (window.cmpReady) {
                console.log("✅ CMP is ready, requesting bids...");
                clearTimeout(timeoutRef.current); // Cancel any existing timeout
                requestBids();
            } else {
                console.warn("⏳ Waiting for CMP...");
                clearTimeout(timeoutRef.current); // Cancel previous timeout before setting a new one
                timeoutRef.current = setTimeout(waitForCMP, 500); // Retry every 500ms
            }
        }

        function executeAdScripts(container) {
            const scripts = container.getElementsByTagName("script");

            for (let i = 0; i < scripts.length; i++) {
                const oldScript = scripts[i];
                const newScript = document.createElement("script");

                if (oldScript.src) {
                    newScript.src = oldScript.src;
                    newScript.async = true;
                } else {
                    newScript.textContent = oldScript.textContent;
                }

                oldScript.parentNode.replaceChild(newScript, oldScript); // Replace the script inside the ad container
            }
        }

        function injectAdInIframe(adSlotId, adContent) {
            const adSlot = document.getElementById(adSlotId);
            if (!adSlot) {
                console.error(`❌ Ad slot ${adSlotId} not found`);
                return;
            }

            // Clear previous content
            adSlot.innerHTML = "";

            // Create an iframe
            const iframe = document.createElement("iframe");
            iframe.style.width = "100%"; // Adjust to match ad size
            iframe.style.height = "100%";
            iframe.style.border = "none";
            iframe.scrolling = "no"; // Prevent scrolling
            iframe.allowTransparency = "true";

            // Append iframe to ad slot
            adSlot.appendChild(iframe);

            // Write the ad content inside the iframe
            const iframeDoc = iframe.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <style>
                        body { margin: 0; padding: 0; }
                    </style>
                </head>
                <body>
                    ${adContent} <!-- Inject Ad HTML Content -->
                </body>
                </html>
            `);
            iframeDoc.close();
        }


        function requestBids() {
            if (hasRequested) return; // Prevent duplicate requests
            setHasRequested(true);

            const adUnits = [
                {
                    code: adSlotId,
                    mediaTypes: {
                        banner: { sizes: [[300, 50],[300, 250],[728, 90]] },
                    },
                    bids: [
                        {
                            bidder: "seedtag",
                            params: {
                                adUnitId,
                                publisherId: "8143-9772-01",
                                placement: "inBanner",
                            },
                        },
                    ],
                },
            ];

            window.pbjs.que = window.pbjs.que || [];
            window.pbjs.que.push(() => {
                console.log(`✅ Requesting Prebid ads for slot: ${adSlotId}`);

                window.pbjs.addAdUnits(adUnits);
                window.pbjs.requestBids({
                    bidsBackHandler: (bidResponses) => {
                        console.log(`📢 Prebid responses for ${adSlotId}:`, bidResponses);

                        const adSlot = document.getElementById(adSlotId);
                        if (!adSlot) {
                            console.error(`❌ Ad slot ${adSlotId} not found`);
                            return;
                        }

                        const bids = bidResponses[adSlotId]?.bids || [];
                        if (bids.length > 0) {
                            const winningBid = bids.reduce((prev, current) =>
                                prev.cpm > current.cpm ? prev : current
                            );

                            console.log(`🏆 Winning bid for ${adSlotId}:`, winningBid);

                            if (winningBid.ad) {
                                setShowAd(true);
                                setAdSize([winningBid.width, winningBid.height]);
                                console.log(`🏆 Injecting ad into iframe for ${adSlotId}`);
                                injectAdInIframe(adSlotId, winningBid.ad);
                            }

                        } else {
                            console.warn(`❌ No valid bids returned for ${adSlotId}`);
                            setShowAd(false);
                        }

                        window.pbjs.setTargetingForGPTAsync();
                    },
                });
            });
        }

        waitForCMP(); // Start checking for CMP readiness

        return () => clearTimeout(timeoutRef.current); // Cleanup on unmount

    }, [adUnitId, adSlotId, size]); // Dependencies

    return (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <div
                id={adSlotId}
                ref={adRef}
                style={{
                    width: showAd ? adSize[0] : 0,
                    height: showAd ? adSize[1] : 0,
                    overflow: "hidden",
                    display: showAd ? "block" : "none", // Hide the ad until it is ready
                }}
            />
        </div>
    );
};

export default AdSlot;
