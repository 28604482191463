import { useEffect, useRef, useState } from "react";
import AdPlug from "./AdPlug";

// === QUEUE SETUP ===
const adQueue = [];
let isProcessing = false;
const DELAY_MS = 300;

function enqueueAd(fn) {
    adQueue.push(fn);
    if (!isProcessing) processQueue();
}

function processQueue() {
    if (adQueue.length === 0) {
        isProcessing = false;
        return;
    }

    isProcessing = true;

    const task = adQueue.shift();
    try {
        task();
    } catch (e) {
        console.error("AdSense queue error:", e);
    }

    setTimeout(processQueue, DELAY_MS);
}

// === AD MAP ===
const adMap = {
    "ad_mobile_small": {
        "data-ad-slot": "5355529760",
        style: { display: "inline-block", width: "320px", height: "50px" },
        containerStyle: { textAlign: "center" }
    },
    "ad_h_responsive_1": {
        "data-ad-slot": "9876394555",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
        style: { display: "block" }
    },

    "ad_wide_3": {
        "data-ad-slot": "8406340727",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "ad_wide_2": {
        "data-ad-slot": "9740142696",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "ad_wide_4": {
        "data-ad-slot": "7868937067",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "ad_wide_5": {
        "data-ad-slot": "6316315692",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "ad_wide_1": {
        "data-ad-slot": "1900340807",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "ad_display_728x90_1": {
        "data-ad-slot": "1415342807",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "ad_display_728x90_2": {
        "data-ad-slot": "6476097796",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "ad_display_728x90_3": {
        "data-ad-slot": "3748694816",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "ad_display_728x90_4": {
        "data-ad-slot": "5003234024",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "ad_display_728x90_5": {
        "data-ad-slot": "3421591449",
        style: { display: "inline-block", width: "728px", height: "90px" },
        containerStyle: { textAlign: "center" }
    },

    "in_article_a": {
        "data-ad-slot": "4220635393",
        "data-ad-format": "fluid",
        "data-ad-layout": "in-article",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "in_article_1": {
        "data-ad-slot": "3489255653",
        "data-ad-format": "fluid",
        "data-ad-layout": "in-article",
        "data-full-width-responsive": "true",
        style: { display: "block", textAlign: "center" }
    },

    "infeed_1": {
        "data-ad-slot": "4445483797",
        "data-ad-format": "fluid",
        "data-ad-layout-key": "-6t+ed+2i-1n-4w",
        "data-full-width-responsive": "true",
        style: { display: "block" }
    }
};

const defaultFallback = (
    <AdPlug
        desktop={<div className="adplugg-tag" data-adplugg-zone="myipinfo_homepage_top_banner_desktop"></div>}
        mobile={<div className="adplugg-tag" data-adplugg-zone="myipinfo_homepage_top_banner_mobile"></div>}
    />
);

// === COMPONENT ===
const AdSenseSlot = ({ adId, fallback = defaultFallback }) => {
    const adRef = useRef(null);
    const observerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [adFailed, setAdFailed] = useState(false);
    const isMobile = () => /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const ad = adMap[isMobile() ? "ad_mobile_small" : adId];

    useEffect(() => {
        if (!ad) {
            console.warn(`Ad ID "${adId}" not found in adMap`);
            return;
        }
        if (!adRef.current || isVisible) return;

        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observerRef.current.disconnect(); // stop observing
                }
            },
            { threshold: 0.25 }
        );

        observerRef.current.observe(adRef.current);

        return () => {
            observerRef.current?.disconnect();
        };
    }, [adId, isVisible]);

    useEffect(() => {
        if (isVisible && adRef.current) {
            enqueueAd(() => {
                if (window.adsbygoogle) {
                    try {
                        (window.adsbygoogle = window.adsbygoogle || []).push({});
                    } catch (e) {
                        console.error(`AdSense push error for "${adId}":`, e);
                    }
                }
                // 👇 Check ad fill after a delay (e.g. 2s)
                setTimeout(() => {
                    const el = adRef.current;
                    const filled = el && el.offsetHeight > 0 && el.innerHTML.trim().length > 0;

                    if (!filled) {
                        //console.warn(`❌ Ad "${adId}" not filled, using fallback`);
                        setAdFailed(true);
                    }
                }, 2000); // 2 seconds is usually safe
            });
        }
    }, [isVisible]);

    if (!ad) return null;

    if (adFailed && fallback) {
        return <>{fallback}</>;
    }

    //if (isMobile()) return null;


    return (
        <div style={ad.containerStyle || { textAlign: "center" }}>
            <ins
                ref={adRef}
                className="adsbygoogle"
                style={ad.style}
                data-ad-client="ca-pub-9241068273032987"
                {...ad}
            />
        </div>
    );
};

export default AdSenseSlot;
