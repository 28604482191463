import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CopyToClipBoard = ({ data, copyTxt, copySuccessText, triggerEl, onCopy }) => {
	const [isCopied, setIsCopied] = useState(false);

	const handleCopyClick = () => {
		setIsCopied(true);
		navigator.clipboard.writeText(data);
		onCopy && onCopy();
	};

	useEffect(() => {
		if (isCopied) {
			const timeout = setTimeout(() => {
				setIsCopied(false);
			}, 3000);
			return () => clearTimeout(timeout);
		}
	}, [isCopied]);

	return (
		<OverlayTrigger
			placement={"top"}
			overlay={
				<Tooltip
					id={`tooltip-top`}
					style={{
						width: "150px",
						textAlign: "center",
					}}>
					{isCopied ? copySuccessText || "Copied!" : copyTxt || "Copy"}
				</Tooltip>
			}>
			<div className="flex-[0_0_auto]" onClick={handleCopyClick}>
				{triggerEl}
			</div>
		</OverlayTrigger>
	);
};

export default CopyToClipBoard;
