import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom';

function AuthProvider(props) {
    const { children } = props
    const history = useHistory();  

    const handleRedirectCallback = async appState=> {
        if (history !== undefined) { 
            history.push(appState?.returnTo || window.location.pathname);
        }
    }

    const AUTH0_CLIENT_ID = 'vGNc7qomP7ObFw6jZvdvh9IOo4bNqfMb'
    const AUTH0_DOMAIN = 'https://discovertech.us.auth0.com'

    return (
        <Auth0Provider
            clientId={AUTH0_CLIENT_ID}
            domain={AUTH0_DOMAIN}
            onRedirectCallback={handleRedirectCallback}
            redirectUri={`${window.location.origin}/redirect`}
        >
            {children}
        </Auth0Provider>
    )
}

export default AuthProvider;
