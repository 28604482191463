import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Image } from 'react-bootstrap';

import { LogoutAction } from '../../actions/authActions';
//import MenuAdsterra from "../../components/AdPlacement/MenuAdsterra";

import {
  HPLoginAction,
  HPLogoutAction,
  NavHGClickAction,
  NavHPClickAction,
  NavJSONClickAction,
  NavSpeedTClickAction,
  NavUAClickAction,
} from '../../actions/googleAnalyticsActions';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';

const LanguageSwitcher = React.lazy(() =>
  import('../../components/LanguageSwitcher/LanguageSwitcher')
);

const Menubar = () => {
  const lcpImage = '/images/myip_black.webp'
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  // const [width, setWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const { loginWithRedirect } = useAuth0();

  const path = useLocation().pathname;
  const dispatch = useDispatch();

  const handleLogin = async () => {
    dispatch(HPLoginAction());
    await loginWithRedirect({
      connection: 'google-oauth2',
    });
  };

  const handleLogout = () => {
    dispatch(HPLogoutAction());
    dispatch(LogoutAction());
  };

  const handleNavLinkClick = useCallback(
    (route) => {
      switch (route) {
        case '/':
          dispatch(NavHPClickAction());
          break;
        case '/user-agent':
          dispatch(NavUAClickAction());
          break;
        case '/speed-test':
          dispatch(NavSpeedTClickAction());
          break;
        case '/json-export':
          dispatch(NavJSONClickAction());
          break;
        case '/hash-generator':
          dispatch(NavHGClickAction());
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  // useEffect(() => {
  //   function handleResize() {
  //     setWidth(window.innerWidth);
  //   }
  //   window.addEventListener('resize', handleResize);
  //   const mediaQuery = window.matchMedia('(orientation: portrait)');
  //   const handleOrientationChange = () => {
  //     setWidth(window.innerWidth - (window.innerWidth * 20) / 100);
  //   };
  //   mediaQuery.addEventListener('change', handleOrientationChange);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     mediaQuery.removeEventListener('change', handleOrientationChange);
  //   };
  // }, []);

  const Links = [
    {
      title: 'My IP',
      href: '/',
      icon: 'location',
      t_key: t('general.menu_my_ip'),
    },
    // { title: "IP Lookup", href: "/ip-lookup" },
    {
      title: 'User Agent',
      href: '/user-agent',
      icon: 'phone',
      t_key: t('general.menu_user_agent'),
    },
    {
      title: 'Speed Test',
      href: '/speed-test',
      icon: 'speed',
      t_key: t('general.menu_speed_test'),
    },
    {
      title: 'JSON Export',
      href: '/json-export',
      icon: 'speed',
      t_key: t('general.menu_json_export'),
    },
    {
      title: 'Hash Generator',
      href: '/hash-generator',
      icon: 'speed',
      t_key: t('general.menu_hash_generator'),
    },
    // {
    //   title: 'URL WhiteLister',
    //   href: '/url-whitelister',
    //   icon: 'location',
    //   t_key: t('general.menu_url_whitelister'),
    // },
    // {
    //   title: 'CSV Organizer',
    //   href: '/json-csv-organizer',
    //   icon: 'location',
    //   t_key: t('general.menu_csv_organizer'),
    // },
  ];

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = lcpImage;
    link.as = 'image';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [lcpImage]);

  return (
    <header className="flex w-full px-3 bg-[#deeef0] lg:relative items-center shadow-md justify-between h-[var(--header-height)] lg:h-auto fixed top-0 left-0 z-[99999]">
      <div className="w-[200px] flex gap-3 items-center">
        {/* Toggle Button Start */}
        <div
          className="w-5 h-5 flex items-center"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="relative flex lg:hidden w-5 h-auto [&>*]:inline-block [&>*]:w-full [&>*]:h-[2px] [&>*]:bg-brand [&>*]:origin-center [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:transition-all [&>*]:duration-300">
            <span
              className={`absolute ${
                isMenuOpen ? 'rotate-45 top-0' : 'rotate-0 -top-1.5'
              }`}
            ></span>
            <span
              className={`relative ${isMenuOpen ? 'opacity-0' : ''}`}
            ></span>
            <span
              className={`absolute ${
                isMenuOpen ? '-rotate-45 top-0' : 'rotate-0 top-1.5'
              }`}
            ></span>
          </div>
        </div>
        {/* Toggle Button End */}
        <Link to="/" className="flex items-center lg:w-[110px] h-auto w-[100px]">
          <img
            src={lcpImage}
            className="lg:w-[110px] h-auto w-[100px] mt-[5px]"
            alt="logo"
            width={100}
            height={20}
          />
        </Link>
      </div>
      <nav className="gap-[.75rem] xl:gap-5 flex-1 justify-center hidden lg:flex">
        {Links.map((link) => (
          <Link
            to={link.href}
            key={link.href}
            className={`${
              path === link.href
                ? 'text-brand hover:text-brand after:bg-brand after:scale-x-100'
                : 'text-[#000000] hover:text-[#000000] after:bg-black after:scale-x-0'
            } transition-all inline-block leading-none font-semibold py-[1.5rem] relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 after:transition-all after:ease-in-out hover:after:scale-x-100`}
            onClick={() => handleNavLinkClick(link.href)}
          >
            {link.t_key}
          </Link>
        ))}
      </nav>
      <div className="min-w-[200px] flex items-center justify-end lg:justfiy-start text-black">
        <div className="hidden lg:block">
          <LanguageSwitcher />
        </div>
        {Object.keys(user).length === 0 ? (
          <div
            className={`transition-all underline cursor-pointer`}
            onClick={handleLogin}
          >
            {t('general.log_in')}
          </div>
        ) : (
          <div className="flex gap-[10px] items-center">
            <div
              className={`transition-all underline cursor-pointer hidden lg:block`}
              onClick={handleLogout}
            >
              {t('general.log_out')}
            </div>
            <Image
              height={30}
              width={30}
              src={user?.picture}
              roundedCircle
              className="bg-black rounded-full"
              alt="profile"
              loading="lazy"
            />
          </div>
        )}
      </div>

      {
        !isDesktop && (
          <div
            className={`lg:hidden flex flex-col fixed bottom-0 left-0 z-50 h-[calc(100dvh-58px)] bg-white max-w-[312px] w-full shadow-lg font-signika transition-all duration-300 ease-in-out${
              isMenuOpen ? ' translate-x-0' : ' -translate-x-full'
            }`}
          >
            {
              isMenuOpen && (
                <>
                  <ul className="mb-3">
                    {Links.map((link) => (
                      <li
                        className="relative -after:bottom-1 after:left-0 after:w-full after:h-[2px] after:bg-gradient-to-r after:from-[rgba(2,175,189,1)] after:via-transparent after:to-transparent after:absolute"
                        key={link.href}
                      >
                        <Link
                          to={link.href}
                          className="text-base font-bold block w-full py-[12px] px-[17px]"
                        >
                          {link.t_key}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <LanguageSwitcher isMobile={true} />
                  {Object.keys(user).length !== 0 ? (
                    <div
                      className={`transition-all cursor-pointer font-bold px-[17px] mt-auto py-[12px] relative after:top-0 after:left-0 after:w-full after:h-[2px] after:bg-gradient-to-r after:from-[rgba(2,175,189,1)] after:via-transparent after:to-transparent after:absolute`}
                      onClick={handleLogout}
                    >
                      {t('general.log_out')}
                    </div>
                  ) : (
                    <div
                      className={`transition-all cursor-pointer font-bold px-[17px] mt-auto py-[12px] relative after:top-0 after:left-0 after:w-full after:h-[2px] after:bg-gradient-to-r after:from-[rgba(2,175,189,1)] after:via-transparent after:to-transparent after:absolute`}
                      onClick={handleLogin}
                    >
                      {t('general.log_in')}
                    </div>
                  )}
                </>
              )
            }
          </div>
        )
      }
    </header>
  );
};

export default Menubar;
