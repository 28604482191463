import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from "react-redux";
import { LoginAction } from "../actions/authActions";
import { useHistory } from 'react-router-dom'

var CryptoJS = require("crypto-js");

const Redirect = ()=>{
    const dispatch = useDispatch();
    const CRYPTO_SECRATE_KEY = 'discovertech.us.auth0.com'
    const {
        isAuthenticated,
        user,
        //getAccessTokenSilently, 
    } = useAuth0();
    const history = useHistory();

    useEffect(() => {
         if (isAuthenticated && user) {
            dispatch(LoginAction(user));
            // Encrypt
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), CRYPTO_SECRATE_KEY).toString();
            localStorage.setItem('token', ciphertext);
            history.push('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated,user, dispatch]);

 

    return <>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            color: 'white'
        }}>
            Loading...
        </div>
    </>;
}

export default Redirect;