import React, { Fragment, Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./views/Home/Home";
import Redirect from "./components/Redirect";
import NotFound from "./views/NotFound";

const Footer = React.lazy(() => import("./components/Footer/Footer"));

// Lazy Loaded Routes
const UserAgent = React.lazy(() => import("./views/UserAgent/UserAgent"));
const IpLookUp = React.lazy(() => import("./views/IpLookUp/IpLookUp"));
const SpeedTest = React.lazy(() => import("./views/SpeedTest/SpeedTest"));
const TermsAndCondition = React.lazy(() => import("./views/TermsAndCondition"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const JsonToCSV = React.lazy(() => import("./views/JsonToCSV/JsonToCSV"));
const JsonCSVOrganizer = React.lazy(() => import("./views/JsonCSVOrganizer/JsonCSVOrganizer"));
const URLWhiteLister = React.lazy(() => import("./views/URLWhiteLister/URLWhiteLister"));
const HashGenerator = React.lazy(() => import("./views/HashGenerator/HashGenerator"));
const ContactUs = React.lazy(() => import('./views/ContactUs'));
const TestPage = React.lazy(() => import("./views/Test/Test"));

// Lazy Load Functions
const ScrollToTop = React.lazy(() => import("./ScrollToTop"));

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    const injectAdPluggScript = () => {
      const d = document;
      const id = "adplugg-adjs";
      
      // Remove existing script if present
      const existingScript = d.getElementById(id);
      if (existingScript) {
        existingScript.remove();
      }

      const js = d.createElement("script");
      js.id = id;
      js.async = 1;
      js.src = "//www.adplugg.com/serve/A48226113/js/1.1/ad.js";
      
      const fjs = d.getElementsByTagName("script")[0];
      fjs.parentNode.insertBefore(js, fjs);
    };

    setTimeout(() => {
      // Inject the script after a delay
      injectAdPluggScript();
    }, 1000);
  }, [location.pathname]);

  return (
    <>
      <Fragment>
        <ScrollToTop />
        <div className="pt-[58px] lg:pt-0">
          <Suspense fallback={<div className="min-h-screen">Loading Routes...</div>}>
            <Switch>
              <Route exact path="/redirect" component={Redirect} />
              <Route exact path="/speed-test" component={SpeedTest} />
              <Route exact path="/ip-lookup" component={IpLookUp} />
              <Route exact path="/user-agent" component={UserAgent} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndCondition}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/json-export" component={JsonToCSV} />
              <Route exact path="/json-csv-organizer" component={JsonCSVOrganizer} />
              <Route exact path="/url-whitelister" component={URLWhiteLister} />
              <Route exact path="/hash-generator" component={HashGenerator} />
              <Route exact path="/test" component={TestPage} />
              <Route exact path="/" component={Home} />

              {/* Not Found Page */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default Routes;
