/* eslint-disable react-hooks/exhaustive-deps */
import { isIPv6 } from 'is-ip';
import React, { useEffect, useState, Suspense } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { Link } from 'react-scroll';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getIPversion6,
  GetOwnIpAction,
  IpLookupAction,
  saveMyIpDetails,
  UpdateDetailsIpInfo,
  setPreciseLocation,
  saveUserIPInfo,
  getUserIPInfoHistory,
  clearFindIpDetailAction,
} from '../../actions/ipLookupAction';
import { useTranslation } from 'react-i18next';
import "bootstrap/dist/css/bootstrap.css";
import './Home.css';

import {
  HPAddHistoryAction,
  HPCompleteIPDetailsAction,
  HPIPV4CopyAction,
  HPIPV6CopyAction,
  HPLoginAction,
  HPPreciseLocationAction,
  HPShowHistoryAction,
  HPViewsAction,
} from '../../actions/googleAnalyticsActions';
import ContentCopy from '../../images/content_copy.svg';
import { checkGeolocationPermission } from '../../utils/general';
import Menubar from '../../components/Menubar/Menubar';
import CopyToClipBoard from '../../components/CopyToClipBoard';
import AdPlug from '../../components/Adplug/AdPlug';
import AdSlot from "../../components/Adplug/AdSlot";
import AdSenseSlot from "../../components/Adplug/AdSenseSlot";

const ImageWithText = React.lazy(() => import('../../components/ImageWithText/ImageWithText'));
const Searchbar = React.lazy(() =>
  import('../../components/Searchbar/Searchbar')
);
const GoogleMaps = React.lazy(() => import('./GoogleMaps'));

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [ip, setIp] = useState('');
  const {
    ipLookupError,
    ipLookupData,
    ownIpAddress,
    IPVersion6,
    userIPInfoHistoryData,
    locatinPermissionStatus,
    ipLookupLoader,
  } = useSelector((state) => state.ipLookupReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [Error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [showHistoryTable, setshowHistoryTable] = useState(
    localStorage.getItem('loginWithShowIPHistoryClick') ? true : false
  );
  const [cookies, setCookie] = useCookies(['ipHistory']);
  const [isIPv4Copied, setIPv4Copied] = useState(false);
  const [isIPv6Copied, setIPv6Copied] = useState(false);
  const [showCookiesIPHistory, setShowCookiesIPHistory] = useState(false); // eslint-disable-line

  const [showHistoryTooltip, setShowHisoryTooltip] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    dispatch(HPLoginAction());
    await loginWithRedirect({
      connection: 'google-oauth2',
    });
  };

  const handleHistoryTableShowHide = () => {
    if (user?.email) {
      if (!showHistoryTable && user?.email) {
        dispatch(getUserIPInfoHistory(user?.email));
      }
      if (!showHistoryTable) {
        dispatch(HPShowHistoryAction({}));
      }
      setshowHistoryTable(!showHistoryTable);
    } else {
      localStorage.setItem('loginWithShowIPHistoryClick', true);
      handleLogin();
    }
  };

  const storeCookies = async (ipAddress, time, location) => {
    if (user?.email) {
      await dispatch(
        saveUserIPInfo({ ipAddress, time, location }, user?.email)
      );
      await dispatch(getUserIPInfoHistory(user?.email));
      await setshowHistoryTable(true);
    } else {
      handleLogin();
    }
  };

  const successCallback = (position) => {
    const { latitude, longitude } = position.coords;
    console.log("success callback working...");
    if (ipLookupData?.location) {
      let tempLocation = ipLookupData;
      tempLocation.location.latitude = latitude;
      tempLocation.location.longitude = longitude;
      dispatch(setPreciseLocation(tempLocation));
    }
  };

  const handleclickPriciseLocation = async (e) => {
    dispatch(HPPreciseLocationAction());
    const permission_status = await checkGeolocationPermission();
    if (permission_status === 'granted') {
      console.log('permission_status', permission_status);
      navigator.geolocation.getCurrentPosition(successCallback, (error) => {
        console.log('error after permission granted', error);
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
    }
  }

  // Get Width for ADS Placement
  useEffect(() => {
    // function handleResize() {
    //   setWidth(window.innerWidth);
    // }
    // window.addEventListener('resize', handleResize);
    // const handleOrientationChange = () => {
    //   setWidth(window.innerWidth - (window.innerWidth * 20) / 100);
    // };
    // window
    //   .matchMedia('(orientation: portrait)')
    //   .addEventListener('change', handleOrientationChange);
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    //   window
    //     .matchMedia('(orientation: portrait)')
    //     .removeEventListener('change', handleOrientationChange);
    // };
  }, []);

  useEffect(() => {
    dispatch(HPViewsAction({}));
    if (!Object.keys(ownIpAddress).length) {
      dispatch(GetOwnIpAction());
      dispatch(getIPversion6());
    }
    if (localStorage.getItem('loginWithShowIPHistoryClick')) {
      dispatch(getUserIPInfoHistory(user?.email));
      localStorage.removeItem('loginWithShowIPHistoryClick');
    }
    // Return a cleanup function to clear IP details on component unmount
    return () => {
      dispatch(
        IpLookupAction(
          (ownIpAddress.IPv4 && ownIpAddress.IPv4) || (IPVersion6 && IPVersion6)
        )
      );
      dispatch(clearFindIpDetailAction());
    };
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('MyIpDetail');
    if (!data) {
      dispatch(saveMyIpDetails(ownIpAddress.IPv4));
    }
  }, [ownIpAddress.IPv4, ipLookupData]);

  useEffect(() => {
    if (Object.keys(ownIpAddress).length && !Object.keys(ipLookupData).length) {
      dispatch(
        IpLookupAction(
          (ownIpAddress.IPv4 && ownIpAddress.IPv4) ||
            (isIPv6(IPVersion6) && IPVersion6)
        )
      );
    }
  }, [ownIpAddress]);

  useEffect(() => {
    if (ipLookupError) {
      setError(ipLookupError);
    }
    setShowError(true);
  }, [ipLookupError]);

  const submitHandler = () => {
    dispatch(HPCompleteIPDetailsAction({}));
    setShowDetails(true);
    dispatch(UpdateDetailsIpInfo(ownIpAddress.IPv4));
  };

  const handleSearchButton = (e, ipParam) => {
    e.preventDefault();
    if (!ipParam) {
      dispatch(
        IpLookupAction(
          (ownIpAddress.IPv4 && ownIpAddress.IPv4) ||
            (isIPv6(IPVersion6) && IPVersion6)
        )
      );
      setShowError(true);
      setError('Please enter a valid IP address...!');
    } else {
      setIp(ipParam);
      dispatch(IpLookupAction(ipParam, true));
      setShowDetails(true);
      dispatch(UpdateDetailsIpInfo(ipParam));
    }
  };

  useEffect(() => {
    let timeout;
    if (isIPv4Copied === true) {
      timeout = setTimeout(() => {
        setIPv4Copied(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isIPv4Copied]);

  useEffect(() => {
    let timeout;
    if (isIPv6Copied === true) {
      timeout = setTimeout(() => {
        setIPv6Copied(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isIPv6Copied]);

  useEffect(() => {
    let timeout;
    if (showHistoryTooltip === true) {
      timeout = setTimeout(() => {
        setShowHisoryTooltip(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [showHistoryTooltip]);

  // Extract frequently used values to variables
  const ipv4Address =
    (ipLookupData.IPv4 && !ipLookupData.IPv6
      ? ipLookupData.IPv4
      : ownIpAddress.IPv4 && !ipLookupData.IPv4 && !ipLookupData.IPv6
      ? ownIpAddress.IPv4
      : 'Not Detected') || '';

  const ipv6Address =
    (ipLookupData.IPv6 && ipLookupData.IPv6) ||
    (isIPv6(IPVersion6) && IPVersion6) ||
    t('general.not_detected');

  // const locationLatitude = ipLookupData?.location?.latitude || 0;
  // const locationLongitude = ipLookupData?.location?.longitude || 0;

  return (
    <>
      <Menubar />

      <section itemscope itemtype="http://schema.org/WebPage" className="p-3 !pb-20 bg-gradient-to-r from-[#243949] to-[#01B1BD]">
        <div className="flex mx-auto sm:max-w-5xl md:px-10 px-[0px] my-[1rem]">
          <Searchbar onSearch={handleSearchButton} />
        </div>
        <div
          className="flex flex-col p-6 max-w-5xl w-full mx-auto rounded-3xl"
          style={{
            background: 'linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3',
          }}
          itemscope
          itemprop="mainEntity"
          itemtype="http://schema.org/PostalAddress"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            <div className="flex flex-col gap-2">
              <h1 className="font-semibold uppercase">
                {t('home.my_ip_address')}
              </h1>
              <div
                className="flex flex-col rounded-2xl gap-1 flex-1"
                style={{
                  background:
                    'linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3',
                }}
              >
                <div className="flex flex-row gap-2 p-3 border-b-2 border-[#5D9FA8] flex-1 items-start md:items-center">
                  <div className="font-semibold" itemprop="addressLocality">{t('general.ipv4')}:</div>
                  <div className="text-[#4A5571]" itemprop="streetAddress">{ipv4Address}</div>
                  {ipLookupData.IPv4 || ownIpAddress.IPv4 ? (
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip
                          id={`tooltip-top`}
                          style={{
                            width: '150px',
                            textAlign: 'center',
                          }}
                        >
                          {isIPv4Copied ? 'Copied!' : 'Copy to clipboard'}
                        </Tooltip>
                      }
                    >
                      <svg
                        width="43"
                        height="50"
                        viewBox="0 0 43 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-auto w-4 cursor-pointer md:mt-0 mt-[2px] flex-[0_0_auto]"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            ipLookupData.IPv4
                              ? ipLookupData.IPv4
                              : ownIpAddress.IPv4
                          );
                          dispatch(HPIPV4CopyAction());
                          setIPv4Copied(true);
                        }}
                      >
                        <path
                          d="M15.5 40C14.125 40 12.9479 39.5104 11.9688 38.5312C10.9896 37.5521 10.5 36.375 10.5 35V5C10.5 3.625 10.9896 2.44792 11.9688 1.46875C12.9479 0.489583 14.125 0 15.5 0H38C39.375 0 40.5521 0.489583 41.5312 1.46875C42.5104 2.44792 43 3.625 43 5V35C43 36.375 42.5104 37.5521 41.5312 38.5312C40.5521 39.5104 39.375 40 38 40H15.5ZM15.5 35H38V5H15.5V35ZM5.5 50C4.125 50 2.94792 49.5104 1.96875 48.5312C0.989583 47.5521 0.5 46.375 0.5 45V10H5.5V45H33V50H5.5Z"
                          fill="#4A5571"
                        />
                      </svg>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex flex-row gap-2 p-3 flex-1 items-start md:items-center">
                  <div className="font-semibold">{t('general.ipv6')}:</div>
                  <div className="text-[#4A5571] break-all" itemprop="postalCode">{ipv6Address}</div>
                  {ipLookupData.IPv6 || (isIPv6(IPVersion6) && IPVersion6) ? (
                    <OverlayTrigger
                      placement={'top'}
                      overlay={
                        <Tooltip
                          id={`tooltip-top`}
                          style={{
                            width: '150px',
                            textAlign: 'center',
                          }}
                        >
                          {isIPv6Copied ? 'Copied!' : 'Copy to clipboard'}
                        </Tooltip>
                      }
                    >
                      <svg
                        width="43"
                        height="50"
                        viewBox="0 0 43 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-auto cursor-pointer md:mt-0 mt-[2px] flex-[0_0_auto]"
                        onClick={() => {
                          navigator.clipboard.writeText(ipv6Address);
                          dispatch(HPIPV6CopyAction());
                          setIPv6Copied(true);
                        }}
                      >
                        <path
                          d="M15.5 40C14.125 40 12.9479 39.5104 11.9688 38.5312C10.9896 37.5521 10.5 36.375 10.5 35V5C10.5 3.625 10.9896 2.44792 11.9688 1.46875C12.9479 0.489583 14.125 0 15.5 0H38C39.375 0 40.5521 0.489583 41.5312 1.46875C42.5104 2.44792 43 3.625 43 5V35C43 36.375 42.5104 37.5521 41.5312 38.5312C40.5521 39.5104 39.375 40 38 40H15.5ZM15.5 35H38V5H15.5V35ZM5.5 50C4.125 50 2.94792 49.5104 1.96875 48.5312C0.989583 47.5521 0.5 46.375 0.5 45V10H5.5V45H33V50H5.5Z"
                          fill="#4A5571"
                        />
                      </svg>
                    </OverlayTrigger>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="font-semibold uppercase">
                {t('home.my_ip_info')}
              </h2>
              <div
                className="flex flex-col p-3 rounded-2xl flex-1"
                style={{
                  background:
                    'linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3',
                }}
              >
                <table itemscope itemtype="http://schema.org/Organization">
                  <tbody>
                    <tr>
                      <td className="font-semibold p-2 flex">
                        {t('home.isp')}:
                      </td>
                      <td className="text-[#4A5571] p-2" itemprop="name">
                        {ipLookupData.internet_provider}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold p-2 flex">
                        {t('general.city')}:
                      </td>
                      <td className="text-[#4A5571] p-2" itemprop="addressLocality">
                        {ipLookupData.City}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold p-2 flex">
                        {t('general.region')}:
                      </td>
                      <td className="text-[#4A5571] p-2" itemprop="addressRegion">
                        {ipLookupData.State}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-semibold p-2 flex">
                        {t('general.country')}:
                      </td>
                      <td className="text-[#4A5571] p-2" itemprop="addressCountry">
                        {ipLookupData.Country}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="text-sm">
      <span className="text-transparent">-----</span>
      </div> */}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center ">
                <h2 className="font-semibold uppercase">
                  {t('home.map_location')}:
                </h2>
                {!locatinPermissionStatus &&
                  ipLookupData?.location?.latitude &&
                  ipLookupData?.location?.longitude && (
                    <span
                      className="underline cursor-pointer text-xs"
                      onClick={handleclickPriciseLocation}
                    >
                      {' '}
                      {t('home.show_precise_location')}
                    </span>
                  )}
              </div>
              <div
                className="flex flex-col items-center rounded-2xl border-2 border-white sm:flex-1 overflow-hidden h-60 sm:h-auto"
                style={{
                  background:
                    'linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3',
                }}
              >
                {ipLookupLoader ? (
                  <ReactLoading
                    type={'spin'}
                    color={'#333'}
                    height={25}
                    width={25}
                    style={{
                      textAlign: 'center',
                      margin: '80px 130px',
                      width: '30px',
                      height: '30px',
                    }}
                  />
                ) : (
                  ipLookupData?.location?.latitude &&
                  ipLookupData?.location?.longitude && (
                    <Suspense fallback={<div>Loading Map...</div>}>
                      <GoogleMaps
                        lat={ipLookupData?.location?.latitude}
                        lng={ipLookupData?.location?.longitude}
                        // key={`location_precise_${locatinPermissionStatus}`}
                      />
                    </Suspense>
                  )
                )}
              </div>

              {/* <div className="text-sm">
      <span className="text-[#4A5571]">Location not accurate?</span>
      </div> */}
            </div>
          </div>

          {ipLookupData?.location?.latitude &&
            ipLookupData?.location?.longitude && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-1">
                <div className="flex flex-col gap-2"></div>
                <div className="flex flex-col gap-2"></div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-end">
                    <span className="font-semibold" itemprop="latitude">
                      {t('general.latitude')}:{' '}
                      {ipLookupData?.location?.latitude
                        ? ipLookupData?.location?.latitude.toFixed(4)
                        : ' '}
                    </span>
                    <span className="font-semibold" itemprop="longitude">
                      {t('general.longitude')}:{' '}
                      {ipLookupData?.location?.longitude
                        ? ipLookupData?.location?.longitude.toFixed(4)
                        : ' '}
                    </span>
                  </div>
                </div>
              </div>
            )}

          <div className="my-8 w-full border-b-2 border-[#C2EFF3]"></div>

          <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mx-auto">
            <button
              onClick={handleHistoryTableShowHide}
              className="flex-1 bg-[#02B1BD] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center"
            >
              {showHistoryTable
                ? `${t('general.hide')} `
                : `${t('general.show')} `}
              {t('home.my_ip_history')}
              <img src="/images/icons/history.svg" alt="icon" className="h-6" width={24} height={24} />
            </button>
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip
                  id={`tooltip-top`}
                  style={{
                    width: '150px',
                    textAlign: 'center',
                    display: showHistoryTooltip ? 'block' : 'none',
                  }}
                >
                  {t('home.ip_added_to_history')}
                </Tooltip>
              }
            >
              <button
                onClick={() => {
                  storeCookies(
                    {
                      IPv4: ip ? ip : ownIpAddress.IPv4 && ownIpAddress.IPv4,
                      IPv6:
                        (isIPv6(IPVersion6) && IPVersion6) ||
                        t('general.not_detected'),
                    },
                    +new Date(),
                    `${ipLookupData.City ? ipLookupData.City + ',' : ''}${
                      ipLookupData.Country ? ipLookupData.Country : ''
                    }`
                  );
                  dispatch(HPAddHistoryAction({}));
                  setShowHisoryTooltip(true);
                }}
                className="flex-1 bg-[#0A1A44] text-[#02B1BD] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center"
              >
                {t('home.add_ip_history')}
                <img src="/images/icons/add.svg" alt="icon" className="h-6" width={24} height={24} />
              </button>
            </OverlayTrigger>
          </div>

          {showHistoryTable && (
            <IpHistoryTable
              cookies={
                showCookiesIPHistory
                  ? cookies
                  : { ipHistory: userIPInfoHistoryData }
              }
              showCookiesIPHistory={showCookiesIPHistory}
              setCookie={setCookie}
            />
          )}
        </div>

        <div className="flex flex-col my-10 justify-center items-center max-w-5xl w-full mx-auto">
          <p className="text-center text-sm uppercase text-white">
            {t('home.show_complete_ip_details')}
          </p>
          <Link to="ad1" spy={true} smooth={true} duration={0} delay={0}>
            <img
              onClick={submitHandler}
              src="/images/icons/down.svg"
              alt="icon"
              className="h-10 my-3 cursor-pointer"
              width={40}
              height={40}
            />
          </Link>
          {/* {showDetails && <Adsterra width={width} />} */}
          {/* <div id="ad1" className="w-full" ref={scrollRef}> */}
          {/* <AdPlacementCustom /> */}
          {/* </div> */}
        </div>
        {/* <ExternalLogger /> */}
        <div id="ipDetails">
          {showDetails && (
            <IpDetails
              ipv6={
                (ipLookupData.IPv6 && ipLookupData.IPv6) ||
                (isIPv6(IPVersion6) && IPVersion6 && IPVersion6) ||
                t('general.not_detected')
              }
            />
          )}
          {showError && <div className="text-red-200 text-center">{Error}</div>}
        </div>
        {/*showDetails && (
          <div className="flex flex-col my-10 justify-center items-center max-w-5xl w-full mx-auto">
            <Adsterra width={width} />
          </div>
        )*/}

        {/*<AdPlug
          desktop={<div className="adplugg-tag" data-adplugg-zone="myipinfo_homepage_top_banner_desktop"></div>}
          mobile={<div className="adplugg-tag" data-adplugg-zone="myipinfo_homepage_top_banner_mobile"></div>}
        />*/}
        <AdSlot adUnitId="35360607" adSlotId="ad-slot-1" size={[728, 90]} />
        {/*<AdSenseSlot adId="ad_display_728x90_1" />*/}
      </section>

      <ImageWithText
        containerClass="px-3 py-20 bg-[#B3C6CB]"
        imageUrl={"/images/homepage/home-page-img-1.webp"}
        imageAlt={"home"}
        title={t('home.comprehensive_guide_title')}
        description={t('home.comprehensive_guide_desc')}
        desktopAd="ad_display_728x90_4"
        mobileAd="ad_display_728x90_4"
      />

      <ImageWithText
        reverseDir={true}
        containerClass="px-3 py-20 bg-white"
        imageUrl={"/images/homepage/home-page-img-2.webp"}
        imageAlt={"home"}
        title={t('home.ip_address_title')}
        description={t('home.ip_address_desc')}
        desktopAd="ad_display_728x90_3"
        mobileAd="ad_display_728x90_3"
      />

      <ImageWithText
        containerClass="px-3 py-20 bg-[#B3C6CB]"
        imageUrl={"/images/homepage/home-page-img-3.webp"}
        imageAlt={"home"}
        title={t('home.how_ip_work_title')}
        description={t('home.how_ip_work_desc')}
        desktopAd="ad_wide_3"
        mobileAd="ad_wide_3"
      />

      <ImageWithText
        reverseDir={true}
        containerClass="px-3 py-20 bg-white"
        imageUrl={"/images/homepage/home-page-img-4.webp"}
        imageAlt={"home"}
        title={t('home.change_ip_title')}
        description={t('home.change_ip_desc')}
        desktopAd="ad_display_728x90_2"
        mobileAd="ad_display_728x90_2"
      />

      <ImageWithText
        containerClass="px-3 py-20 bg-[#B3C6CB]"
        imageUrl={"/images/homepage/home-page-img-5.webp"}
        imageAlt={"home"}
        title={t('home.diff_ip_title')}
        description={t('home.diff_ip_desc')}
        desktopAd="ad_wide_5"
        mobileAd="ad_wide_5"
      />

      <ImageWithText
        reverseDir={true}
        containerClass="px-3 py-20 bg-white"
        imageUrl={"/images/homepage/home-page-img-6.webp"}
        imageAlt={"home"}
        title={t('home.ip_without_permission_title')}
        description={t('home.ip_without_permission_desc')}
        desktopAd="ad_display_728x90_5"
        mobileAd="ad_display_728x90_5"
      />

      <ImageWithText
        containerClass="px-3 py-20 bg-[#B3C6CB]"
        imageUrl={"/images/homepage/home-page-img-7.webp"}
        imageAlt={"home"}
        title={t('home.hide_ip_title')}
        description={t('home.hide_ip_desc')}
        desktopAd="ad_display_728x90_4"
        mobileAd="ad_display_728x90_4"
      />

      <ImageWithText
        reverseDir={true}
        containerClass="px-3 py-20 bg-white"
        imageUrl={"/images/homepage/home-page-img-8.webp"}
        imageAlt={"home"}
        title={t('home.benefit_of_vpn_title')}
        description={t('home.benefit_of_vpn_desc')}
        desktopAd="ad_wide_4"
        mobileAd="ad_wide_4"
      />

      <ImageWithText
        containerClass="px-3 py-20 bg-[#B3C6CB]"
        imageUrl={"/images/homepage/home-page-img-7.webp"}
        imageAlt={"home"}
        title={t('home.how_choose_vpn_title')}
        description={t('home.how_choose_vpn_desc')}
        desktopAd="ad_display_728x90_5"
        mobileAd="ad_display_728x90_5"
      />

      <ImageWithText
        reverseDir={true}
        containerClass="px-3 py-20 bg-white"
        imageAlt={"home"}
        title={t('home.conclusion_title')}
        description={t('home.conclusion_desc')}
        desktopAd="ad_display_728x90_5"
        mobileAd="ad_display_728x90_5"
      />
    </>
  );
};

const IpDetails = ({ ips, ipv6 = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isIPv4Copied, setIPv4Copied] = useState(false);
  const [isIPv6Copied, setIPv6Copied] = useState(false);
  const {
    ipLookupLoader,
    ipLookupData,
    findIpDetail,
  } = useSelector((state) => state.ipLookupReducer);

  useEffect(() => {
    // if (findIpDetail) {
    //  dispatch(IpLookupAction(findIpDetail,true)); //Manual IP search
    // } else if (ips) {
    //  dispatch(IpLookupAction(ips)); //Own IP search.
    // }
  }, [findIpDetail, ips]);

  function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return degrees + '° ' + minutes + '′ ' + seconds + '″ ';
  }

  function latitudeConvertDMS(lat) {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? 'N' : 'S';
    return latitude + ' ' + latitudeCardinal;
  }
  function longitudeConvertDMS(lng) {
    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? 'E' : 'W';
    return longitude + ' ' + longitudeCardinal;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv4Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv4Copied]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv6Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv6Copied]);

  if (ipLookupLoader) {
    return (
      <div className="loading__icon">
        <ReactLoading
          type={'spin'}
          color={'#333'}
          height={25}
          width={25}
          style={{
            textAlign: 'center',
            margin: '0 auto',
            width: '30px',
            height: '30px',
          }}
        />
      </div>
    );
  }
  return (
    <div className="my-10 grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl mx-auto">
      <div className="col-span-1 md:col-span-2 rounded-3xl overflow-hidden home_card_3">
        <div className="p-3 px-8 uppercase text-white bg-[#0A1A44] flex flex-row items-center gap-3">
          {t('home.ip_details_for_ipv4')}: {findIpDetail || ips}
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip
                id={`tooltip-top`}
                style={{ width: '150px', textAlign: 'center' }}
              >
                {isIPv4Copied ? 'Copied!' : 'Copy to clipboard'}
              </Tooltip>
            }
          >
            <img
              onClick={() => {
                navigator.clipboard.writeText(findIpDetail || ips);
                dispatch(HPIPV4CopyAction());
                setIPv4Copied(true);
              }}
              src="/images/icons/copy.svg"
              alt="icon"
              className="h-4 cursor-pointer"
            />
          </OverlayTrigger>
          {ipv6 && (
            <>
              {'IPv6: ' + ipv6}
              {
                ipv6 !== t('general.not_detected') && (
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip
                        id={`tooltip-top`}
                        style={{
                          width: '150px',
                          textAlign: 'center',
                        }}
                      >
                        {isIPv6Copied ? 'Copied!' : 'Copy to clipboard'}
                      </Tooltip>
                    }
                  >
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(ipv6);
                        dispatch(HPIPV6CopyAction());
                        setIPv6Copied(true);
                      }}
                      src="/images/icons/copy.svg"
                      alt="icon"
                      className="h-4 cursor-pointer"
                      loading="lazy"
                    />
                  </OverlayTrigger>
                )
              }
            </>
          )}
        </div>

        {Object.keys(ipLookupData).length <= 1 ? (
          <div className="no-data">
            <p> No data found for {findIpDetail || ips} address </p>
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-3 text-sm px-8">
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.decimal')}:{' '}
              </span>
              {ipLookupData?.decimal}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.hostname')}:{' '}
              </span>
              {ipLookupData?.hostname}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.asn')}:{' '}
              </span>
              {ipLookupData?.ASN}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.isp')}:{' '}
              </span>
              {ipLookupData?.internet_provider}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.services')}:{' '}
              </span>
              {t('general.none_detected')}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.assignment')}:{' '}
              </span>
              {t('general.likely_static_ip')}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.country')}:{' '}
              </span>
              {ipLookupData?.Country}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.state')}/{t('general.region')}:{' '}
              </span>
              {ipLookupData?.State}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">
                {t('general.city')}:{' '}
              </span>
              {ipLookupData?.City}
            </p>
          </div>
        )}
      </div>
      <div className="rounded-3xl overflow-hidden home_card_3 flex flex-col gap-4 text-sm p-6">
        <div className="rounded-2xl overflow-hidden h-60">
          {ipLookupData?.location?.latitude &&
            ipLookupData?.location?.longitude && (
              <Suspense fallback={<div>Loading Map...</div>}>
                <GoogleMaps
                  lat={ipLookupData?.location?.latitude}
                  lng={ipLookupData?.location?.longitude}
                  // key={`location_precise_${locatinPermissionStatus}`}
                />
              </Suspense>
            )}
        </div>
        <p className="text-[#4A5571]">
          <span className="font-semibold text-black">
            {t('general.latitude')}:{' '}
          </span>
          {ipLookupData?.location?.latitude +
            ' (' +
            latitudeConvertDMS(ipLookupData?.location?.latitude) +
            ')'}
        </p>
        <p className="text-[#4A5571]">
          <span className="font-semibold text-black">
            {t('general.longitude')}:{' '}
          </span>
          {ipLookupData?.location?.longitude +
            ' (' +
            longitudeConvertDMS(ipLookupData?.location?.longitude) +
            ')'}
        </p>
      </div>
    </div>
  );
};

const IpHistoryTable = ({ cookies, showCookiesIPHistory }) => {
  const { t } = useTranslation();
  const timeStampToDateTime = (timestamp) =>
    new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(timestamp);

  return (
    <div className="history-table">
      {cookies?.ipHistory?.length > 0 && (
        <div className="flex flex-row overflow-hidden w-full">
          <div className="flex flex-row overflow-auto w-full">
            <table>
              <thead>
                <tr>
                  <th
                    className="md:min-w-[auto] min-w-[250px]"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {t('general.ip_address')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('general.time')}
                  </th>
                  <th style={{ textTransform: 'uppercase' }}>
                    {t('general.location')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {cookies?.ipHistory?.slice(0, 10).map((e) => {
                  if (showCookiesIPHistory) {
                    return (
                      <tr>
                        <td className="">
                          <p className="flex items-start gap-1">
                            <strong>{t('general.ipv4')}</strong>:
                            <span className="inline-block break-all">
                              {e?.ipAddress?.IPv4}
                              {e?.ipAddress?.IPv4 !== 'Not Detected' && (
                                <CopyToClipBoard
                                  triggerEl={
                                    <img
                                      alt="copy icon"
                                      src={ContentCopy}
                                      width={42}
                                      height={50}
                                      className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1"
                                      loading="lazy"
                                    />
                                  }
                                  data={e?.ipAddress?.IPv4}
                                />
                              )}
                            </span>
                          </p>
                          <p className="flex items-start gap-1">
                            <strong>{t('general.ipv6')}</strong>:
                            <span className="inline-block break-all">
                              {e?.ipAddress?.IPv6}
                              {e?.ipAddress?.IPv6 !== 'Not Detected' && (
                                <CopyToClipBoard
                                  triggerEl={
                                    <img
                                      alt="copy icon"
                                      src={ContentCopy}
                                      width={42}
                                      height={50}
                                      className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1"
                                      loading="lazy"
                                    />
                                  }
                                  data={e?.ipAddress?.IPv6}
                                />
                              )}
                            </span>
                          </p>
                        </td>
                        <td className="break-words">
                          {timeStampToDateTime(e?.time)}
                        </td>
                        <td className="break-words">{e?.location}</td>
                      </tr>
                    );
                  } else {
                    let newData = JSON.parse(e.ipdetails);
                    return (
                      <tr>
                        <td style={{ textAlign: 'left' }}>
                          <p className="flex items-start gap-1">
                            <strong>{t('general.ipv4')}:</strong>
                            <span className="flex break-all">
                              {newData?.ipAddress?.IPv4}
                              {newData?.ipAddress?.IPv4 !== 'Not Detected' && (
                                <CopyToClipBoard
                                  triggerEl={
                                    <img
                                      alt="copy icon"
                                      src={ContentCopy}
                                      width={42}
                                      height={50}
                                      className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1"
                                      loading="lazy"
                                    />
                                  }
                                  data={newData?.ipAddress?.IPv4}
                                />
                              )}
                            </span>
                          </p>
                          <p className="flex items-start gap-1">
                            <strong>{t('general.ipv6')}:</strong>
                            <span className="flex break-all">
                              {newData?.ipAddress?.IPv6}
                              {newData?.ipAddress?.IPv6 !== 'Not Detected' && (
                                <CopyToClipBoard
                                  triggerEl={
                                    <img
                                      alt="copy icon"
                                      src={ContentCopy}
                                      width={42}
                                      height={50}
                                      className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1"
                                      loading="lazy"
                                    />
                                  }
                                  data={newData?.ipAddress?.IPv6}
                                />
                              )}
                            </span>
                          </p>
                        </td>
                        <td className="break-words">
                          {timeStampToDateTime(newData?.time)}
                        </td>
                        <td className="break-words">{newData?.location}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
